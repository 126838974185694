<template>
    <div style="text-align: left;">
        <el-card>
            
            <el-page-header @back="$oucy.back" content="">
            </el-page-header>
        <el-card shadow="never" style="margin-bottom: 25px;" class="m-t-50">
            <div slot="header" class="my-header">
                <div class="bold">回收站</div>
                <div>

                    <!-- <el-button type="default" size="mini" @click="$oucy.go('/enter/newsSave')">发布新闻</el-button> -->
                    <el-button type="default" size="mini">全部删除</el-button>
                    <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
                    <!-- <el-button size="mini">收起<i class="el-icon-caret-top el-icon--right"></i></el-button> -->
                    <!-- <el-button size="mini" @click="hasList=!hasList">{{hasList?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasList?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button> -->
                </div>
            </div>
            <div v-show="hasList">
                <template>
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>


                        <el-table-column prop="address" label="标题" show-overflow-tooltip>
                        </el-table-column>

                        <el-table-column label="操作" align="right" width="250">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="editClassify(scope.row)">恢复</el-button>
                                <el-button size="mini" @click="delMessageBox('deleteClassify',scope.row)" type="danger" plain>彻底删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        <el-divider></el-divider>
                        <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                        </el-pagination>
                    </div>
                </template>
                <div class="text-center">
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">
                        当前您还没有相册哦！
                    </div>
                    <el-button type="default" size="mini" @click="classifyDialogFormVisible=true">立即创建</el-button>
                </div>
            </div>
        </el-card>
        </el-card>
        <el-dialog width="500px" title="创建相册" :visible.sync="classifyDialogFormVisible">
            <el-form :model="classifyForm" ref="classifyForm" :rules="classifyFormRule" label-width="120px">
                <el-form-item label="分类" prop="category">
                    <el-select v-model="classify" placeholder="请选择分类" style="width: 340px">
                        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="name" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitClassifyForm">保 存</el-button>
                    <el-button @click="classifyDialogFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { enterpriseNewsAlbum, enterpriseNewsAlbumItem } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Photo",
    data() {
        return {
            // 下面是验证的
            classifyFormRule: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                classify: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ]
            },
            hasList: true,
            classifyDialogFormVisible: !true,
            list: [{ name: '家具分类1', id: '1' }, { name: '家具分类2', id: '2' }],
            activeName: '1',
            classifyForm: '',
            classify: '',
            name: '',
            value: '',
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-08',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-06',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-07',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }],
            multipleSelection: [],
            prlductData: {},
            enterpriseId: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.getAllEnterpriseNewsAlbumsClient()
    },
    methods: {
        handleClick() {},
        submitClassifyForm() {},
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.findAllFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.findAllFurnitureSpu();
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 查询企业的新闻册
        getAllEnterpriseNewsAlbumsClient() {
            enterpriseNewsAlbum.getAllEnterpriseNewsAlbumsClient({ enterpriseId: this.enterpriseId }).then(res => {

            })
        },
        addAttr: function() {
            const me = this;
            me.$prompt('请输入分类名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '分类名称不正确'
            }).then(({ value }) => {
                enterpriseNewsAlbum.addEnterpriseNewsAlbum({enterpriseId: this.enterpriseId, newsAlbumName: value }).then(res => {

                })
            });
        },
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}
</style>